import React       from 'react';
import { graphql } from 'gatsby';

import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper
                    from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import HeaderImage  from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import HeaderImg    from '@interness/web-core/src/components/media/HeaderImg/HeaderImg';
import Separator    from '@interness/web-core/src/components/structure/Separator/Separator';

import Services          from '../components/Services';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import MoreExpander      from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';


function IndexPage(props) {
  return (
    <>
      <HeaderImage>
        <HeaderImg id="116-startseite-2023"/>
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <Heading subtitle={'Wirtschaftsprüfung und Steuerberatung'}>Steuerberatungskanzlei Axel
          Johannvordersielhorst</Heading>
        <section>
          <p>Willkommen bei der Steuerberatungskanzlei von Axel Johannvordersielhorst in Schwelm – Ihrem zuverlässigen
            Partner für umfassende Steuerberatung und Wirtschaftsprüfung. Unsere Kanzlei steht für Kompetenz,
            Vertrauenswürdigkeit und eine maßgeschneiderte Beratungstätigkeit, die sich an Ihren individuellen
            Bedürfnissen orientiert.</p>
          <MoreExpander>
            <p>Unser erfahrenes Team, angeführt von Steuerberater und Wirtschaftsprüfer Axel Johannvordersielhorst,
              bietet
              eine breite Palette an Dienstleistungen von Finanzbuchhaltung und Steuerberatung bis hin zur
              betriebswirtschaftlichen Beratung an. Der Bereich Wirtschaftsprüfung – insbesondere die Abwicklung
              gesetzlicher Abschlussprüfungen – wird in Kooperation mit einer renommierten
              Wirtschaftsprüfungsgesellschaft
              angeboten. Wir verstehen, dass jede finanzielle Situation einzigartig ist, und setzen uns daher für eine
              persönliche und individuell zugeschnittene Beratung ein.</p>
            <p>In unserer Steuerberatungskanzlei legen wir großen Wert auf eine transparente und verständliche
              Kommunikation. Wir sind bestrebt, komplexe steuerliche Sachverhalte für Sie klar und nachvollziehbar zu
              gestalten, so dass Sie stets informiert Entscheidungen treffen können. Egal, ob es um die Prüfung Ihrer
              Unternehmensbilanzen oder um die Optimierung Ihrer Steuerlast geht, unser Ziel ist es, Ihre finanziellen
              Interessen bestmöglich zu vertreten und zu unterstützen.</p>
            <p>Unsere Kanzlei in Schwelm ist nicht nur ein Ort der fachlichen Expertise, sondern auch ein Raum, in dem
              Vertrauen und Diskretion großgeschrieben werden. Wir freuen uns darauf, Sie in allen Fragen rund um
              Steuerberatung, Wirtschaftsprüfung und Rechnungswesen zu beraten und zu begleiten.</p>
            <p>Ihr Axel Johannvordersielhorst</p>
          </MoreExpander>
        </section>
        <LiveAnnouncements/>
        <Separator/>
        <section>
          <Services type="grid"/>
        </section>
        <Separator/>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query IndexQuery {
        media: directusMediaCollection(name: {eq: "home"}) {
            media {
                file {
                    localFile {
                        name
                        publicURL
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
